// Update user's preferred language if weglot language changes
window.addEventListener("turbo:load", (event) => 
  { 
if (typeof Weglot !== 'undefined') {
  Weglot.on("languageChanged", function(newLang) {
    if (gon.user_id) {
      const formData = new FormData();
      formData.append('user[preferred_language]', newLang);
      Rails.ajax({
        type: "PUT",
        url: `/users`,
        data: formData
      });
    }
    else {
      const formData = new FormData();
      formData.append('locale', newLang);
      Rails.ajax({
        type: "POST",
        url: `/set_locale_from_weglot`,
        data: formData
      });
  }});
}})